<template>
  <ButtonUI
    :disabled="disabled || loading"
    @click="$emit('click')"
  >
    <slot v-if="!loading" />
    <ButtonLoader 
      v-else
      :backgroundColor="backgroundColorForLoading"
    />
  </ButtonUI>
</template>

<script>
import ButtonUI from '@/components/ui/ButtonUI';
import ButtonLoader from '@/components/ui/ButtonLoader';

export default {
  name: 'ButtonLoadingUI',
  components: {
    ButtonUI,
    ButtonLoader,
  },

  props: {
    loading: Boolean,
    disabled: Boolean,
    backgroundColorForLoading: String,
  },
}
</script>
