<template>
  <button
    class="ButtonUI"
    :class="{ 'ButtonUI--disabled': this.disabled }"
    @click="$emit('click')"
  >
    <span class="ButtonUI__content"><slot /></span>
  </button>
</template>

<script>
export default {
  name: 'ButtonUI',

  props: {
    disabled: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-theme {
  .ButtonUI {
    background-color: #9EA5F7;

    &:hover {
      background-color: #959DFF;
    }

    &:active {
      background-color: #8A93FF;
    }

    &__content {
      color: $darkThemeBgOne;
    }

    &--disabled {
      background-color: #4E5054;

      .ButtonUI__content {
        color: $darkThemeBgTwo;
      }
    }
  }
}

.ButtonUI {
  position: relative;
  width: 100%;
  min-width: 160px;
  height: 40px;
  overflow: hidden;
  border: none;
  border-radius: 4px;
  background-color: $blue;
  outline: none;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
    background-color: #5E6CF8;
  }

  &:active {
    background-color: #5967F9;
  }

  // Контент
  &__content {
    color: #FFFFFF;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 0.875rem;
  }

  // Модификаторы
  &--disabled {
    pointer-events: none;
    background-color: $borderColor;

    .ButtonUI__content {
      color: $darkGrey;
    }
  }
}
</style>
