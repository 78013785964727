<template>
  <div
    :style="{ 'background-color': backgroundColor }"
    class="ButtonLoader"
  >
    <IconLogo class="ButtonLoader__icon" />
  </div>
</template>

<script>
import IconLogo from '@/assets/icons/logo.svg';

export default {
  name: 'ButtonLoader',
  components: {
    IconLogo,
  },

  props: {
    backgroundColor: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.ButtonLoader {
  display: grid;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  &__icon {
    position: relative;
    display: block;
    width: 100%;
    height: 40%;
    z-index: 2;

    path {
      opacity: 0.3;
      stroke: #fff;
      stroke-dasharray: 2000;
      stroke-dashoffset: 2794;
      animation: DrawLine 2s linear infinite;
    }
  }
}
</style>
